import React from 'react';
import Hero from '../components/Hero/Hero';
import Features from '../components/Features/Features';
import DescContent from '../components/Desc/Content';
import { Roadmap } from '../components/roadmap/index';
import { FaqBody } from '../components/faq/index';

const HomeBody = () => {
    return (
        <>
            <Hero />
            <DescContent />
            <Roadmap />
            <Features />
            <FaqBody />
        </>
    );
};

export { HomeBody };
