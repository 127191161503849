/* eslint-disable react/jsx-max-depth */
import React, { useEffect } from 'react';
import { Container, Section } from '../../style/globalStyles';
import {
    ContentRow,
    TextWrapper,
    Heading,
    ContentColumn,
} from './ContentStyles.js';

import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useTranslation } from 'react-i18next';

const data = {
    reverse: true,
    inverse: false,
};


export const Roadmap = () => {
    const {
        inverse,
        reverse } = data;
    const initial = { opacity: 0, y: 30 };
    const animation = useAnimation();

    const { ref, inView } = useInView({ threshold: 0.2 });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });
        }
    }, [inView, animation]);

    const { t } = useTranslation('common');

    return (
        <Section inverse={ inverse } ref={ ref }>
            <Container>
                <ContentRow reverse={ reverse }>
                    <ContentColumn>
                        <TextWrapper>
                            <Heading
                                initial={ initial }
                                transition={ { delay: 0.5, duration: 0.6 } }
                                animate={ animation }
                                inverse={ inverse }
                            >
                                {t('rd-head')}
                            </Heading>
                        </TextWrapper>
                        <VerticalTimeline>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={ { background: '#white', color: 'black' } }
                                contentArrowStyle={ { borderRight: '7px solid white' } }
                                dateClassName="dateColor"
                                date={ t('rd-1-date') }
                                iconStyle={ { background: 'white', color: 'white' } }
                            >
                                <h3 className="vertical-timeline-element-title" style={ {color: '#B63F3F'} }>
                                    {t('rd-step1')}
                                </h3>
                                <p>
                                    {t('rd-1-desc')}
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                date={ t('rd-2-date') }
                                dateClassName="dateColor"
                                contentStyle={ { background: 'white', color: 'black' } }
                                contentArrowStyle={ { borderRight: '7px solid white' } }
                                iconStyle={ { background: '#071c2f', color: 'black' } }
                            >
                                <h3 className="vertical-timeline-element-title" style={ {color: '#B63F3F'} }>
                                    {t('rd-step2')}
                                </h3>
                                <p>
                                    {t('rd-2-desc')}
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                date={ t('rd-3-date') }
                                dateClassName="dateColor"
                                contentStyle={ { background: 'white', color: 'black' } }
                                contentArrowStyle={ { borderRight: '7px solid white' } }
                                iconStyle={ { background: '#071c2f', color: 'black' } }
                            >
                                <h3 className="vertical-timeline-element-title" style={ {color: '#B63F3F'} }>
                                    {t('rd-step3')}
                                </h3>
                                <p>
                                    {t('rd-3-desc')}
                                </p>
                            </VerticalTimelineElement>
                        </VerticalTimeline>
                    </ContentColumn>
                </ContentRow>
            </Container>
        </Section>
    );
};
