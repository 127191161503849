/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, MainHeading } from '../../style/globalStyles';
import { HeroButtonGo, HeroVideo, HeroSection, HeroText, ButtonWrapper, HeroButton, ArrowWrapper } from './HeroStyles';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const ReadMore = ({ children, isFr, readMore, showLess }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p className="text" style={ {color: 'white', lineHeight: '1.5'} }>
            {isReadMore ? text.slice(0, isFr ? 188 : 169) : text}
            <span onClick={ toggleReadMore } className="read-or-hide">
                {isReadMore ? readMore : showLess}
            </span>
        </p>
    );
};

const ModalExt = ({ buttonClaim, headModal, modalTxt, buttonClaim2, isFr, readMore, showLess }) => {
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <div>

            <ButtonWrapper>
                <HeroButton onClick={ onOpenModal }>
                    { buttonClaim }
                </HeroButton>
            </ButtonWrapper>
            <Modal open={ open } onClose={ onCloseModal } center classNames={ { closeIcon: 'customIcon', modal: 'customModal' } }>
                <div>
                    <h2 style={ {color: 'white', textAlign: 'center', marginBottom: '2%', borderBottom: '1px solid #2d3748'} }>
                        { headModal }
                    </h2>
                    <ReadMore isFr={ isFr } readMore={ readMore } showLess={ showLess }>
                        {modalTxt}
                    </ReadMore>
                    <div style={ {textAlign: 'center', marginTop: '2%'} }>
                        <Link to={ {pathname: 'https://www.tropee.com/collection/sacem'} } target="_blank">
                            <HeroButtonGo>
                                { buttonClaim2 }
                            </HeroButtonGo>
                        </Link>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const renderer = ({ completed, formatted, props }) => {
    if (completed) {
        // Render a completed state
        return (
            <>
                <HeroText>
                    {props.subhero}
                </HeroText>
                <ModalExt
                    showLess={ props.showLess } readMore={ props.readMore } isFr={ props.isFr }
                    buttonClaim={ props.buttonClaim } headModal={ props.headModal }
                    backModal={ props.backModal } modalTxt={ props.modalTxt }
                    buttonClaim2={ props.buttonClaim2 }
                />
            </>
        );
    } else {
        // Render a countdown
        return (
            <>
                <HeroText>
                    {props.coming}
                </HeroText>
                {formatted.hours}
                {props.h}
                {formatted.minutes}
                {props.m}
                {formatted.seconds}
                {props.s}
            </>
        );
    }
};

const Hero = () => {
    const { t, i18n } = useTranslation('common');
    const isFr = i18n.language === 'fr' ? true : false;

    const scrollTo = () => {
        const element = document.getElementById('a');

        element.scrollIntoView({
            behavior: 'smooth',
        });
    };

    return (
        <HeroSection>
            <HeroVideo src="./assets/hero.mp4" autoPlay muted loop playsInline />
            <Container>
                <MainHeading>
                    { t('coming') }
                    <br />
                    <Countdown
                        coming={ t('date-drop') } daysInHours date={ '2021-11-25T12:00:00' }
                        renderer={ renderer } modalTxt={ t('modal-txt') }
                        buttonClaim={ t('buttonClaim') } buttonClaim2={ t('buttonClaim2') } headModal={ t('head-modal') }
                        subhero={ t('subhero') } showLess={ t('show-less') }
                        isFr={ isFr } readMore={ t('read-more') }
                        h={ t('hours') } m={ t('min') } s={ t('sec') }
                    />
                </MainHeading>
            </Container>
            <ArrowWrapper onClick={ () => scrollTo() } style={ { cursor: 'pointer'} }>
                <MdKeyboardArrowDown size="8rem" />
            </ArrowWrapper>
        </HeroSection>
    );
};

export default Hero;
