import React, { useEffect } from 'react';
import { Container, Section } from '../../style/globalStyles';
import {
    ContentRow,
    TextWrapper,
    Heading,
    ContentColumn,
} from './ContentStyles.js';
import Faq from 'react-faq-component';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const data = {
    reverse: true,
    inverse: false,
    headline: 'FAQ',
};

const stylesF = {
    bgColor: 'red',
    rowContentColor: 'grey',
    arrowColor: 'red',
};

export const FaqBody = () => {
    const {
        headline,
        inverse,
        reverse } = data;
    const initial = { opacity: 0, y: 30 };
    const animation = useAnimation();

    const { ref, inView } = useInView({ threshold: 0.2 });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });
        }
    }, [inView, animation]);
    const urlArticle = 'https://cryptagency.medium.com/creation-dun-wallet-simple-sur-la-blockchain-polygon-3c8252395010';
    const { t } = useTranslation('common');
    const faqRows = t('faq', {returnObjects: true});
    const faqDest = _.map(faqRows, (item, idx) => {
        if (idx === 4) {
            const splited = _.split(item.content, '##');
            const component = (
                <p>
                    {`${splited[0]}`}
                    <a href={ urlArticle } target="_blank" rel="noreferrer" style={ {color: 'white'} }>
                        {`${splited[1]}`}
                    </a>
                </p>
            );
            return {...item, content: component};
        } else {
            return item;
        }
    });
    const dataFaq = {rows: faqDest, styles: stylesF};

    return (
        <Section inverse={ inverse } ref={ ref }>
            <Container>
                <ContentRow reverse={ reverse }>
                    <ContentColumn>
                        <TextWrapper>
                            <Heading
                                initial={ initial }
                                transition={ { delay: 0.5, duration: 0.6 } }
                                animate={ animation }
                                inverse={ inverse }
                            >
                                {headline}
                            </Heading>
                        </TextWrapper>
                        <Faq
                            data={ dataFaq }
                            styles={ {
                                titleTextColor: 'white',
                                rowTitleColor: 'white',
                                bgColor: '#071C2F',
                                rowContentColor: 'white',
                                arrowColor: 'white',
                                rowContentPaddingLeft: '10px',
                                rowContentPaddingBottom: '20px',
                                rowContentPaddingTop: '15px',

                            } }
                        />
                    </ContentColumn>
                </ContentRow>
            </Container>
        </Section>
    );
};
